import React from "react";
import { inject } from "mobx-react";
import { Container } from "reactstrap";
import { FooterTopPart } from "ComponentsUIV2/Footer";
import { generateUrl } from "Services/withDefaultParams";
import useDefaultParams from "Services/useDefaultParams";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import Routes from "Configs/routes";
import DeclinationContainerNew from "Containers/DeclinationContainerNew";
import { isMoscowSiteCode, isPiterSiteCode } from "Utils/siteConfig";

const FooterTopPartContainer = (props) => {
	const {
		ApplicationModel: {
			text,
			windowSizeModel: { windowSizes },
			currentRegion,
			siteCode,
		},
		is404Layout,
	} = props;
	const routeParams = useParams();
	const { regionUrl } = useDefaultParams(routeParams);

	//Тарифы для дома

	let urlDomashnijInternet = generateUrl(Routes.Rates.route, {
		regionUrl: regionUrl,
	});

	let urlInternetITv = generateUrl(Routes.RatesTags.route, {
		regionUrl: regionUrl,
		tagCode: "internet-i-tv",
	});

	let urlInternetTvMobile = generateUrl(Routes.RatesTags.route, {
		regionUrl: regionUrl,
		tagCode: "internet-tv-mobile",
	});

	let urlInternetIMobilnayaSvyaz = generateUrl(Routes.RatesTags.route, {
		regionUrl: regionUrl,
		tagCode: "internet-i-mobilnaya-svyaz",
	});

	let urlOnlineKinoteatr = generateUrl(Routes.RatesTags.route, {
		regionUrl: regionUrl,
		tagCode: "online-kinoteatr",
	});

	let urlOrderToHome = generateUrl(Routes.OrderToHome.route, {
		regionUrl: regionUrl,
	});
	//Провайдеры

	let urlProviderDetailsMts = generateUrl(Routes.ProviderDetails.route, {
		regionUrl: regionUrl,
		providerName: "mts",
	});

	let urlProviderDetailsRostelecom = generateUrl(Routes.ProviderDetails.route, {
		regionUrl: regionUrl,
		providerName: "rostelecom",
	});

	let urlProviderDetailsBeeline = generateUrl(Routes.ProviderDetails.route, {
		regionUrl: regionUrl,
		providerName: "beeline",
	});

	let urlProviderDetailsDomRu = generateUrl(Routes.ProviderDetails.route, {
		regionUrl: regionUrl,
		providerName: "dom-ru",
	});

	let urlProviderDetailsMegafon = generateUrl(Routes.ProviderDetails.route, {
		regionUrl: regionUrl,
		providerName: "megafon",
	});

	let urlProviders = generateUrl(Routes.Providers.route, {
		regionUrl: regionUrl,
	});

	//Cсылки на страницы

	let urlAddressDistricts = generateUrl(Routes.AddressDistricts.route, {
		regionUrl: regionUrl,
	});

	let urlOrderOffice = generateUrl(Routes.OrderOffice.route, {
		regionUrl: regionUrl,
	});

	let urlOrderOuthome = generateUrl(Routes.OrderOuthome.route, {
		regionUrl: regionUrl,
	});

	let urlReviews = generateUrl(Routes.Reviews.route, {
		regionUrl: regionUrl,
	});

	let urlRating = generateUrl(Routes.Rating.route, {
		regionUrl: regionUrl,
	});

	let urlActions = generateUrl(Routes.Actions.route, {
		regionUrl: regionUrl,
	});

	// Мобильная связь - мобильные тарифы

	let urlRatesMobileUnlim = generateUrl(Routes.RatesMobile.route, {
		regionUrl: regionUrl,
		tagCode: "bezlimitnyj-internet",
	});

	let urlRatesMobileMezhdunarodnye = generateUrl(Routes.RatesMobile.route, {
		regionUrl: regionUrl,
		tagCode: "mezhdunarodnye",
	});

	let urlRatesMobilePerenosNomera = generateUrl(Routes.RatesMobile.route, {
		regionUrl: regionUrl,
		tagCode: "perenos_nomera",
	});

	let urlRatesMobileEsim = generateUrl(Routes.RatesMobile.route, {
		regionUrl: regionUrl,
		tagCode: "esim",
	});

	let urlRatesMobile = generateUrl(Routes.RatesMobile.route, {
		regionUrl: regionUrl,
	});

	// Мобильная связь - мобильные операторы
	let urlOperatorDetailsMts = generateUrl(Routes.OperatorDetails.route, {
		regionUrl: regionUrl,
		operator: "mts",
	});

	let urlOperatorDetailsTinkoff = generateUrl(Routes.OperatorDetails.route, {
		regionUrl: regionUrl,
		operator: "tinkoff",
	});

	let urlOperatorDetailsYota = generateUrl(Routes.OperatorDetails.route, {
		regionUrl: regionUrl,
		operator: "yota",
	});

	let urlOperatorDetailsBeeline = generateUrl(Routes.OperatorDetails.route, {
		regionUrl: regionUrl,
		operator: "beeline",
	});

	let urlOperatorDetailsTele2 = generateUrl(Routes.OperatorDetails.route, {
		regionUrl: regionUrl,
		operator: "tele-2",
	});

	let urlOperatorDetailsSbermobile = generateUrl(Routes.OperatorDetails.route, {
		regionUrl: regionUrl,
		operator: "sbermobile",
	});

	let urlOperatorDetailsMegafon = generateUrl(Routes.OperatorDetails.route, {
		regionUrl: regionUrl,
		operator: "megafon",
	});

	let urlAllOperators = generateUrl(Routes.Operatory.route, {
		regionUrl: regionUrl,
	});

	// Мобильная связь - красивые номера

	let urlBesplatnye = generateUrl(Routes.BeautifulNumbers.route, {
		regionUrl: regionUrl,
		tagCode: "besplatnye",
	});

	let urlSerebrjanyj = generateUrl(Routes.BeautifulNumbers.route, {
		regionUrl: regionUrl,
		tagCode: "serebrjanyj",
	});

	let urlBronzovyj = generateUrl(Routes.BeautifulNumbers.route, {
		regionUrl: regionUrl,
		tagCode: "bronzovyj",
	});

	let urlZolotoj = generateUrl(Routes.BeautifulNumbers.route, {
		regionUrl: regionUrl,
		tagCode: "zolotoj",
	});

	let urlAllNumbers = generateUrl(Routes.BeautifulNumbers.route, {
		regionUrl: regionUrl,
	});

	let connectInternetRoute = "";
	let connectInternetUrl = "";

	if (isMoscowSiteCode(siteCode)) {
		connectInternetRoute = Routes.ConnectInternet.route;
	} else if (isPiterSiteCode(siteCode)) {
		connectInternetRoute = Routes.HomeInternet.route;
	}

	if (connectInternetRoute) {
		connectInternetUrl = generateUrl(connectInternetRoute, {
			regionUrl: regionUrl,
		});
	}

	return (
		<Container>
			<DeclinationContainerNew showContainer={true} currentRegion={currentRegion}>
				<FooterTopPart
					siteCode={siteCode}
					is404Layout={is404Layout}
					windowSizes={windowSizes}
					textRatesHome={text.rates_home}
					urlDomashnijInternet={urlDomashnijInternet}
					urlInternetITv={urlInternetITv}
					urlInternetTvMobile={urlInternetTvMobile}
					urlInternetIMobilnayaSvyaz={urlInternetIMobilnayaSvyaz}
					urlOnlineKinoteatr={urlOnlineKinoteatr}
					urlOrderToHome={urlOrderToHome}
					textDomashnijInternet={text.servicesHomeInternet}
					textInternetITv={text.internetPlusTv}
					textInternetTvMobile={text.TARIFFS_WITH_INET_TV_MOBILE}
					textInternetIMobilnayaSvyaz={text.internetPlusMobile}
					textOnlineKinoteatr={text.onlineCinemas}
					textOrderToHome={text.upperMenuFindInternet}
					textMobileRatesmobile={text.mobile_ratesmobile}
					urlRatesMobileUnlim={urlRatesMobileUnlim}
					textRatesMobileUnlim={text.internet_unlim}
					urlRatesMobileMezhdunarodnye={urlRatesMobileMezhdunarodnye}
					textRatesMobileMezhdunarodnye={text.roaming_abroad}
					urlRatesMobilePerenosNomera={urlRatesMobilePerenosNomera}
					textRatesMobilePerenosNomera={text.number_transfer}
					urlRatesMobileEsim={urlRatesMobileEsim}
					textRatesMobileEsim={text.esim_uppercase}
					urlRatesMobile={urlRatesMobile}
					ratesMobile_text={text.ALL_RATES}
					textMobileOperators={text.mobile_operators}
					urlOperatorDetailsMts={urlOperatorDetailsMts}
					urlOperatorDetailsTinkoff={urlOperatorDetailsTinkoff}
					urlOperatorDetailsYota={urlOperatorDetailsYota}
					urlOperatorDetailsBeeline={urlOperatorDetailsBeeline}
					urlOperatorDetailsTele2={urlOperatorDetailsTele2}
					urlOperatorDetailsSbermobile={urlOperatorDetailsSbermobile}
					urlOperatorDetailsMegafon={urlOperatorDetailsMegafon}
					providersText={text.menuProvidersV2}
					providersInText={text.PROVIDERS_BY_AREA}
					currentRegion={currentRegion}
					urlProviderDetailsMts={urlProviderDetailsMts}
					TextMts={text.MTS}
					urlProviderDetailsRostelecom={urlProviderDetailsRostelecom}
					TextRostelecom={text.Rostelecom}
					urlProviderDetailsBeeline={urlProviderDetailsBeeline}
					TextBeeline={text.Beeline}
					urlProviderDetailsDomRu={urlProviderDetailsDomRu}
					TextDomRu={text.DomRu}
					urlProviderDetailsMegafon={urlProviderDetailsMegafon}
					urlProviders={urlProviders}
					TextMegafon={text.Megafon}
					moreLink_text={text.all_providers}
					TextTinkoff={text.Tinkoff}
					TextYota={text.Yota}
					TextTele2={text.Tele2}
					TextSbermobile={text.Sbermobile}
					textAddress={text.searchByAddress}
					urlAddressDistricts={urlAddressDistricts}
					textInternetInOffice={text.headerForBusiness}
					urlOrderOffice={urlOrderOffice}
					textInternetOuthome={text.menuOrderOuthome}
					urlOrderOuthome={urlOrderOuthome}
					textReviews={text.REVIEWS_TITLE}
					urlReviews={urlReviews}
					textRating={text.heroRatingProviders}
					urlRating={urlRating}
					textActions={text.ACTIONS_TITLE_DEFAULT}
					urlActions={urlActions}
					textBesplatnye={text.besplatnye}
					urlBesplatnye={urlBesplatnye}
					textBronzovyj={text.bronzovyj}
					urlBronzovyj={urlBronzovyj}
					textSerebrjanyj={text.serebrjanyj}
					urlSerebrjanyj={urlSerebrjanyj}
					textZolotoj={text.zolotoj}
					urlZolotoj={urlZolotoj}
					textBeautifulNumbers={text.BEAUTIFUL_NUMBERS}
					mobileBlockTitle={text.mobile_communications}
					textAllOperators={text.all_operators}
					urlAllOperators={urlAllOperators}
					textAllNumbers={text.all_numbers}
					urlAllNumbers={urlAllNumbers}
					textConnectInternet={text.connectInternet}
					urlConnectInternet={connectInternetUrl}
				/>
			</DeclinationContainerNew>
		</Container>
	);
};

export default inject("ApplicationModel")(FooterTopPartContainer);
