import React from "react";
import loadable from "@loadable/component";
import LoadingSpinnerHOC from "HOC/LoadingSpinnerHOC";

export const Business = loadable(() => import("./business"), {
	fallback: <LoadingSpinnerHOC />,
});
export const AddReview = loadable(() => import("./addReview"), {
	fallback: <LoadingSpinnerHOC />,
});

export const SelectRegions = loadable(() => import("./selectRegion"), {
	fallback: <LoadingSpinnerHOC />,
});
export const MainPage = loadable(() => import("./mainPage"), {
	fallback: <LoadingSpinnerHOC />,
});
export const ConnectInternet = loadable(() => import("./connectInternet"), {
	fallback: <LoadingSpinnerHOC />,
});
export const HomeInternet = loadable(() => import("./homeInternet"), {
	fallback: <LoadingSpinnerHOC />,
});
export const ProviderDetails = loadable(() => import("./provider"), {
	fallback: <LoadingSpinnerHOC />,
});

export const OperatorDetails = loadable(() => import("./operator"), {
	fallback: <LoadingSpinnerHOC />,
});

export const Compare = loadable(() => import("./compareTariffications"), {
	fallback: <LoadingSpinnerHOC />,
});

export const Partner = loadable(() => import("./partner"), {
	fallback: <LoadingSpinnerHOC />,
});

export const Contacts = loadable(() => import("./contacts"), {
	fallback: <LoadingSpinnerHOC />,
});

export const PayWarranty = loadable(() => import("./about/payWarranty"), {
	fallback: <LoadingSpinnerHOC />,
});

export const Actions = loadable(() => import("./actions"), {
	fallback: <LoadingSpinnerHOC />,
});

export const BeautifulNumbers = loadable(() => import("./beautifulNumbers"), {
	fallback: <LoadingSpinnerHOC />,
});

export const RatesMobile = loadable(() => import("./ratesMobile"), {
	fallback: <LoadingSpinnerHOC />,
});

export const Rates = loadable(() => import("./rates"), {
	fallback: <LoadingSpinnerHOC />,
});

export const SiteMap = loadable(() => import("./sitemap"), {
	fallback: <LoadingSpinnerHOC />,
});

export const AboutCompany = loadable(() => import("./about/company"), {
	fallback: <LoadingSpinnerHOC />,
});
